import { graphql, useStaticQuery } from "gatsby"

export default function useRegisterInfo() {
  const result = useStaticQuery(
    graphql`
      query RegisterQuey {
        allContentfulPetysRegistro {
          nodes {
            banners {
              banners {
                description
                file {
                  url
                }
              }
              tituloBanner
              descripcionBanner {
                descripcionBanner
              }
            }
            metaTitulo
            metaDescripcion {
              metaDescripcion
            }
            slug
            tituloIntroductorio
            descripcionIntroductoria {
              descripcionIntroductoria
            }
            departamentos {
              codigo
              nombre
              codigoDane
            }
            ciudad {
              codigo
              nombre
              coverage
              codigoDane
              codigoDepartamento
              codigoDaneDepartamento
            }
            razas {
              animal_id
              nombre_raza
            }
            urlMarcoInstagram {
              title
              description
              file {
                url
              }
            }
          }
        }
      }
    `
  )

  const registerInfo = result.allContentfulPetysRegistro.nodes[0]

  const getDepartmentName = id =>
    registerInfo.departamentos.find(
      deparment => parseInt(deparment.codigo) === parseInt(id)
    ).nombre

  const getDepartmentCode = name =>
    registerInfo.departamentos.find(deparment =>
      deparment.nombre.toString().contains(name.toString())
    ).codigo

  const getCityName = id =>
    registerInfo.ciudad.find(city => parseInt(city.codigo) === parseInt(id))
      .nombre

  const getCityCode = name =>
    registerInfo.ciudad.find(city =>
      city.nombre.toString().contains(name.toString())
    ).codigo

  const filterCity = deparmentCode =>
    registerInfo.ciudad.filter(
      city => city.codigoDepartamento === deparmentCode.toString()
    )

  return {
    registerInfo,
    getDepartmentName,
    getDepartmentCode,
    getCityName,
    getCityCode,
    filterCity,
  }
}

import React from "react"
import HuellaPurple from '../../../shared/assets/images/huella-lista.svg';
import HuellaOrange from '../../../shared/assets/images/huella-orange.svg';
import "./navigator-steps.scss"

const NavigatorSteps = props => {

  const { totalSteps, activeStep, className } = props

  var indents = [];

  for (var i = 0; i < totalSteps; i++) {
    indents.push(i);
  }

  return (
    <div className={"f-navigator-steps " + (className ? className : '')}>
      {
        indents.map((item, index) => {
          return (
            <figure className={"f-step-container " + (item + 1 > activeStep ? "disabled" : '')} key={index}>
              <img src={(item + 1) < activeStep ? HuellaPurple : HuellaOrange} alt="Huella" />
              <span className="f-step-number">{item + 1}</span>
            </figure>
          )
        })
      }
    </div>
  )
}

export default NavigatorSteps

import React from "react"
import "./custom-checkbox-native.scss"

const CustomCheckboxNative = props => {
  const {
    error,
    errorText,
    label,
    className,
    checked,
    inputRef,
    ...params
  } = props

  return (
    <div className={"f-checkbox-native " + className}>
      <label className="container">
        <input
          className="checkbox"
          type="checkbox"
          ref={inputRef}
          {...params}
        />
        <span className="checkmark"></span>
        <div className="f-checkbox-native__text">{label}</div>
      </label>
      {error && <p className="f-checkbox-native-error">{errorText}</p>}
    </div>
  )
}

export default CustomCheckboxNative

import React, { useEffect, useState } from "react"
import CustomInputText from "../../../shared/components/atoms/inputText/InputText"
import CustomCheckboxNative from "../../../shared/components/atoms/custom-checkbox-native/custom-checkbox-native"
import NavigatorSteps from "../../../components/atoms/navigator-steps/navigator-steps"
import { sessionStorage } from "../../../shared/utils/storage"
import Button from "../../../shared/components/atoms/button/button"
import homeLink from "../../../shared/utils/siteUrl"
import { DIRECTION_PATTERN } from "../../../shared/constants/appConstants"
import { useForm } from "react-hook-form"
import "./third-step-content.scss"

const ThirdStepContent = props => {
  const { title, registerInfo } = props

  const filterCity = deparmentCode =>
    registerInfo.ciudad.filter(
      city => parseInt(city.codigoDepartamento) === parseInt(deparmentCode)
    )

  const [defaultData] = useState(sessionStorage.getItem("infoRegister"))
  const [citys, setCitys] = useState("")
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      department: defaultData?.department || defaultData?.countryState || "",
      city: defaultData?.city || "",
      direction: defaultData?.address || defaultData?.direction || "",
      directionDetail:
        defaultData?.addressDetail || defaultData?.directionDetail || "",
    },
  })

  const onSubmit = data => {
    let infoRegister = sessionStorage.getItem("infoRegister")
    infoRegister.department = data.department
    infoRegister.city = data.city
    infoRegister.direction = data.direction.trim().replace("  ", " ")
    infoRegister.directionDetail = data.directionDetail
      .trim()
      .replace("  ", " ")
    infoRegister.tycPhysicalContat = data.tycPhysicalContat
      ? data.tycPhysicalContat
      : false

    sessionStorage.setItem("infoRegister", infoRegister)

    window.location.href = homeLink + "registro/paso-4"
  }

  const handleDepartment = code => {
    setCitys(filterCity(code))
  }

  useEffect(() => {
    if (
      !defaultData ||
      !defaultData.email ||
      !defaultData.cellphone ||
      !defaultData.name ||
      !defaultData.lastname ||
      !defaultData.gender ||
      !defaultData.day ||
      !defaultData.month ||
      !defaultData.year ||
      !defaultData.password
    ) {
      window.location.href = "/registro/"
    }

    const initialDepartment = async () => {
      await handleDepartment(defaultData.department || defaultData.countryState)
      setValue("city", defaultData?.city)
    }

    if (defaultData.department || defaultData.countryState) {
      initialDepartment()
    }
  }, [])

  return (
    <div className="f-first-step-content">
      <h2 className="f-title--purple f-form-register-title">{title}</h2>

      <NavigatorSteps totalSteps={4} activeStep={3} />

      <h2 className="f-title--purple f-register-title">Datos de contacto</h2>

      <form
        className="f-register-form f-rows-container"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <div className="f-row">
          <div className="f-container-input">
            <div className="f-select">
              <select
                name="department"
                id="department"
                ref={register({ required: "Este campo es requerido" })}
                onChange={event => handleDepartment(event.target.value)}
              >
                <option defaultValue disabled value="">
                  Departamento*
                </option>
                {registerInfo.departamentos.map((department, index) => {
                  return (
                    <option key={index} value={department.codigo}>
                      {department.nombre}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>

          <div className="f-container-input">
            <div className="f-select">
              <select
                defaultValue=""
                name="city"
                id="city"
                ref={register({ required: "Este campo es requerido" })}
              >
                <option disabled value="">
                  Ciudad*
                </option>
                {citys &&
                  citys.length > 0 &&
                  citys.map((city, index) => {
                    return (
                      <option
                        key={index}
                        value={city.codigo}
                        selected={
                          defaultData && defaultData.city
                            ? city.codigo.toString() ===
                              defaultData.city.toString()
                            : false
                        }
                      >
                        {city.nombre}
                      </option>
                    )
                  })}
              </select>
            </div>
          </div>
        </div>

        <div className="f-row f-row-password">
          <div className="f-container-input">
            <CustomInputText
              fullWidth={true}
              label="Dirección (opcional)"
              id="direction"
              inputProps={{
                name: "direction",
                maxLength: 200,
                placeholder: "Cra 50 #30sur -45",
              }}
              inputRef={register({
                maxLength: 200,
                pattern: DIRECTION_PATTERN,
              })}
              error={errors.direction ? true : false}
              errorText={errors.direction ? "Campo inválido" : ""}
            />
          </div>

          <div className="f-container-input">
            <CustomInputText
              fullWidth={true}
              label="Detalle dirección (opcional)"
              id="directionDetail"
              inputProps={{
                name: "directionDetail",
                maxLength: 100,
                placeholder: "Alcalá, torre palmar, 1202",
              }}
              inputRef={register({
                maxLength: 200,
              })}
              error={errors.directionDetail ? true : false}
              errorText={errors.directionDetail ? "Campo inválido" : ""}
            />
          </div>
        </div>

        <div className="f-buttons-container">
          <Button
            className="f-btn-purple f-button-prev"
            // typeButton="button"
            title="Atrás"
            href="registro/paso-2"
          />

          <Button
            className="f-primary-purple f-button-next"
            typeButton="button"
            type="submit"
            title="Siguiente"
            disabled={!(watch("department") && watch("city"))}
          />
        </div>
      </form>
    </div>
  )
}

export default ThirdStepContent

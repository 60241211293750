//Endpoints constants
export const successStatus = 200
export const failureStatus = 400

//Form validation constants
export const NAME_PATTERN = /^([^\s][A-zÀ-ž\s]+)*$/
export const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const PASSWORD_PATTERN = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30}$/
export const DIRECTION_PATTERN = /^[ a-zA-Z0-9#°áéíóúÁÉÍÓÚäëïöüÄËÏÖÜñÑ,.;:()¿?¡!_-]+$/
export const URL_PATTERN = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
export const NUMBER_PATTERN = /^[0-9]*$/i
export const FORMAT_NUMBER_PATTERN = /(\d)(?=(\d{3})+(?!\d))/g
export const FORMAT_NUMBER_LETTERS_PATTERN = /^[ a-zA-Z0-9áéíóúÁÉÍÓÚäëïöüÄËÏÖÜñÑ,.;:()¿?¡!_-]+$/
export const NAME_MIN_LENGTH = 3
export const PASSWORD_MIN_LENGTH = 6
export const PASSWORD_MAX_LENGTH = 40
export const CELLPHONE_LENGTH = 10
export const YEAR_MAX_LENGTH = 4
export const YEAR_MAX = new Date().getFullYear()
export const YEAR_MIN = new Date().getFullYear() - 100
export const DATE_MAX_LENGTH = 2
export const DATE_MAX = 31
export const DATE_MIN = 1
export const QUANTITY_MIN = 1

//Activity id
export const EMBAJADORAS_ID = "EMBAJADOR_PETYS_2022"

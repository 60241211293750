import React from "react"
import SectionTitleMobile from "../../../components/atoms/section-title-mobile/section-title-mobile"
import Logo from "../../../assets/images/logo-petys.svg"
import siteUrl from "../../../utils/siteUrl"
import "./register-layout.scss"

const BlogHeader = props => {
  const { redirection } = props

  return (
    <div className="f-register-layout">
      <div className="f-register-header">
        <div className="f-register-header-container">
          <a href={siteUrl + redirection} className="f-register-back-home">
            <i className="fas fa-chevron-left"></i>
            Volver al inicio
          </a>

          <a href={siteUrl} className="f-register-logo">
            <img src={Logo} alt="Logo Petys" />
          </a>
        </div>
      </div>

      <div className="f-register-layout-content">{props.children}</div>
    </div>
  )
}

export default BlogHeader
